import { STAFF_ACTIONS } from '../actions/actionTypes';

const defaultState = {
  staffDetails: {
    dsoID: null,
    email: null,
    firstName: null,
    id: null,
    lastName: null,
    userID: null,
  },
  officeAccesses: {
    staffId: null,
    data: [],
    filters: {
      page: 1,
      limit: 50,
    },
  },
  rateLimits: {
    data: [],
    filters: {
      limit: 10,
      page: 1,
    },
    total: 0,
  },
  staffRateLimits: {
    staffId: null,
    rateLimitDA: null,
    rateLimitDN: null,
    rateLimitFD: null,
    rateLimitRDH: null,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STAFF_ACTIONS.FETCH_STAFF_BY_ID_SUCCESS: {
      return {
        ...state,
        staffDetails: action.payload,
      };
    }
    case STAFF_ACTIONS.FETCH_STAFF_OFFICE_ACCESSES_SUCCESS: {
      return {
        ...state,
        officeAccesses: {
          ...state.officeAccesses,
          staffId: action.payload.staffId,
          data: action.payload.data,
        },
      };
    }
    case STAFF_ACTIONS.ADD_ACCESS_TO_STAFF_ENTITY_SUCCESS: {
      return {
        ...state,
        officeAccesses: {
          ...state.officeAccesses,
          data: [...state.officeAccesses.data, action.payload],
        },
      };
    }
    case STAFF_ACTIONS.DELETE_ACCESS_TO_STAFF_ENTITY_SUCCESS: {
      return {
        ...state,
        officeAccesses: {
          ...state.officeAccesses,
          data: state.officeAccesses.data.filter((item) => item.id !== action.payload.accessId),
        },
      };
    }
    case STAFF_ACTIONS.ADD_ACCESS_TO_STAFF_ENTITY_REQUEST: {
      const { limit, page } = action.payload;

      return {
        ...state,
        rateLimits: {
          ...state.rateLimits,
          filters: {
            limit: limit || 10,
            page: page || 1,
          },
        },
      };
    }
    case STAFF_ACTIONS.FETCH_RATE_LIMIT_HISTORY_SUCCESS: {
      const { data, total } = action.payload;

      return {
        ...state,
        rateLimits: {
          ...state.rateLimits,
          data: [...state.rateLimits.data, ...data],
          total,
        },
      };
    }
    case STAFF_ACTIONS.FETCH_RATE_LIMITS_REQUEST: 
    case STAFF_ACTIONS.FETCH_RATE_LIMITS_SUCCESS: {
      return {
        ...state,
        staffRateLimits: {
          ...state.staffRateLimits,
          ...action.payload,
        },
      };
    }
    case STAFF_ACTIONS.SAVE_RATE_LIMITS_SUCCESS:
    case STAFF_ACTIONS.DELETE_RATE_LIMIT_SUCCESS: {
      return {
        ...state,
        staffRateLimits: {
          ...state.staffRateLimits,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
