import moment from 'moment';
import tempMeeApi from '../apis/tempMee';
import { AVAILABILITY_ACTIONS } from './actionTypes';
import { getAuthHeaders } from './authActions';

export const getAvailabilityList = (params) => async (dispatch, getState) => {
  try {
    const currUserId = getState().users.currentUser.id;

    const searchParams = {
      officeID: currUserId,
      state: params.state || undefined,
      profession: params.profession || undefined,
      radius: params.radius ? Math.min(Math.max(Number(params.radius), 1), 50) : 50,
      date: params.date || moment().format('YYYY-MM-DD'),
    };

    dispatch({ type: AVAILABILITY_ACTIONS.FETCH_AVAILABILITY_REQUEST, payload: searchParams });
    
    const headers = await getAuthHeaders();
    const response = await tempMeeApi.get(
      `/offer-discovery/admin/professionals/on-call-availability`,
      {
        headers,
        params: searchParams,
      },
    );

    dispatch({ type: AVAILABILITY_ACTIONS.FETCH_AVAILABILITY_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: AVAILABILITY_ACTIONS.FETCH_AVAILABILITY_FAILURE, payload: err });
  }
};
