import { AVAILABILITY_ACTIONS } from '../actions/actionTypes';

const initialState = {
  availability: {
    data: [],
    total: 0,
    searchParams: {
      state: null,
      profession: null,
      radius: null,
      date: null,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AVAILABILITY_ACTIONS.FETCH_AVAILABILITY_REQUEST:
      return {
        ...state,
        availability: {
          ...state.availability,
          searchParams: action.payload,
        },
      };
    case AVAILABILITY_ACTIONS.FETCH_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: {
          ...state.availability,
          data: action.payload.data,
          total: action.payload.data.length,
        },
      };
    default:
      return state;
  }
};
