const style = {
  tableText: {
    fontWeight: '600',
    fontSize: '12px',
    fontFamily: 'Nunito',
    color: '#435963',
    paddingBlock: '10px',
  },
};

export const availabilityColumns = () => [
  {
    selector: (row) => row.professions,
    name: 'Profession',
    format: (row) => (row.professions || []).join(', '),
    style: style.tableText,
  },
  {
    selector: (row) => row.professionalId,
    name: 'ID',
    format: (row) => row.professionalId,
    style: style.tableText,
  },
  {
    selector: (row) => row.firstName,
    name: 'First Name',
    format: (row) => row.firstName,
    style: style.tableText,
  },
  {
    selector: (row) => row.lastName,
    name: 'Last Name',
    format: (row) => row.lastName,
    style: style.tableText,
  },
  {
    selector: (row) => row.city,
    name: 'City',
    format: (row) => row.city,
    style: style.tableText,
  },
  {
    selector: (row) => row.state,
    name: 'State',
    format: (row) => row.state,
    style: style.tableText,
  },
  {
    selector: (row) => row.zip,
    name: 'Zip',
    format: (row) => row.zip,
    style: style.tableText,
  },
  {
    selector: (row) => row.loyaltyLevel,
    name: 'Loyalty',
    format: (row) => row.loyaltyLevel,
    style: style.tableText,
  },
  {
    selector: (row) => row.distance,
    name: 'Distance',
    format: (row) => row.distance,
    style: style.tableText,
  },
];

export const availabilityConditionalRowStyles = [
  {
    when: (row) => row.isOnCall === true,
    style: { backgroundColor: '#FDFFC9' },
  },
  {
    when: (row) => row.isOnCall === false,
    style: { backgroundColor: '#C1F5B9' },
  },
];
